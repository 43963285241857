import React, {useState} from "react";
import {
    Icon,
    Flex,
    Text,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    FormControl,
    Input, ModalFooter, Button,
} from "@chakra-ui/react";
import {
    MdOutlineMoreHoriz,
    MdOutlineImportExport, MdOutlineAdd,
} from "react-icons/md";


export default function Banner(props) {
    const {handleDownloadCsv, handleAddClick, pageTitle, ...rest} = props;

    const textColor = useColorModeValue("secondaryGray", "white");
    const textHover = useColorModeValue(
        {color: "secondaryGray.900", bg: "unset"},
        {color: "secondaryGray.500", bg: "unset"}
    );
    const iconColor = useColorModeValue("brand.500", "white");
    const bgList = useColorModeValue("white", "whiteAlpha.100");
    const bgShadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
        "unset"
    );
    const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        {bg: "secondaryGray.400"},
        {bg: "whiteAlpha.50"}
    );
    const bgFocus = useColorModeValue(
        {bg: "secondaryGray.300"},
        {bg: "whiteAlpha.100"}
    );

    // Ellipsis modals
    const {
        isOpen: isOpen1,
        onOpen: onOpen1,
        onClose: onClose1,
    } = useDisclosure();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    const [urlInputValue, setUrlInputValue] = useState('');
    const [UrlInputIsInvalid, setUrlInputIsInvalid] = useState(false);

    return (
        <>
            <Menu isOpen={isOpen1} onClose={onClose1}>
                <MenuButton
                    align='center'
                    justifyContent='center'
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w='37px'
                    h='37px'
                    lineHeight='100%'
                    onClick={onOpen1}
                    borderRadius='10px'
                    {...rest}>
                    <Icon as={MdOutlineMoreHoriz} color={iconColor} w='24px' h='24px'/>
                </MenuButton>
                <MenuList
                    w='300px'
                    minW='unset'
                    maxW='150px !important'
                    border='transparent'
                    backdropFilter='blur(63px)'
                    bg={bgList}
                    boxShadow={bgShadow}
                    borderRadius='20px'
                    p='15px'>
                    <MenuItem
                        transition='0.2s linear'
                        color={textColor}
                        _hover={textHover}
                        p='0px'
                        borderRadius='8px'
                        _active={{
                            bg: "transparent",
                        }}
                        _focus={{
                            bg: "transparent",
                        }}
                        mb='10px'
                        onClick={() => {
                            setUrlInputValue('');
                            setUrlInputIsInvalid(false);
                            onOpen();
                        }}
                    >
                        <Flex align='center'>
                            <Icon as={MdOutlineAdd} h='20px' w='20px' me='8px'/>
                            <Text fontSize='md' fontWeight='400'>
                                Add
                            </Text>
                        </Flex>
                    </MenuItem>
                    <MenuItem
                        transition='0.2s linear'
                        color={textColor}
                        _hover={textHover}
                        p='0px'
                        borderRadius='8px'
                        _active={{
                            bg: "transparent",
                        }}
                        _focus={{
                            bg: "transparent",
                        }}
                        mb='10px'
                        onClick={handleDownloadCsv}
                    >
                        <Flex align='center'>
                            <Icon as={MdOutlineImportExport} h='20px' w='20px' me='8px'/>
                            <Text fontSize='md' fontWeight='400'>
                                Export csv
                            </Text>
                        </Flex>
                    </MenuItem>
                </MenuList>
            </Menu>

            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                isCentered={true}
                closeOnOverlayClick={false}
            >
                <form className="input">
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>Add {pageTitle} Request Download</ModalHeader>

                        <ModalBody pb={6}>
                            <FormControl>
                                <Input
                                    ref={initialRef}
                                    placeholder='Url'
                                    isInvalid={UrlInputIsInvalid}
                                    errorBorderColor='crimson'
                                    textColor={textColor}
                                    onChange={(event) => {
                                        setUrlInputValue(event.target.value);
                                        setUrlInputIsInvalid(false);
                                    }}
                                />
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button type='submit' colorScheme='blue' mr={3} onClick={() => {
                                if(urlInputValue === '') {
                                    setUrlInputIsInvalid(true);
                                } else {
                                    onClose();
                                    handleAddClick(urlInputValue);
                                }
                            }}>
                                Save
                            </Button>
                            <Button onClick={() => {
                                onClose();
                                setUrlInputValue('');
                                setUrlInputIsInvalid(false);
                            }}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
        </>
);
}
