import Axios from "axios";
import { API_SERVER } from "../config/constant";

const axios = Axios.create({
    baseURL: `${API_SERVER}`,
    headers: { "Content-Type": "application/json", 'Accept': 'application/json', },
});


axios.interceptors.request.use(
    async config => {
        if (!config.url.includes('auth/')) {
            let user = localStorage.getItem("user");
            user = JSON.parse(user);
            config.headers.Authorization = user.token ? `Bearer ${user.token}` : "";
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

axios.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {

        if (!error.response.config.url.includes('auth/') && (error.response.status === 403 || error.response.status === 401)) {
            localStorage.removeItem('user');
            // const history = useHistory();
            window.location.href = "/auth/signin";
        }
        return Promise.reject(error);
    }
);

export default axios;
