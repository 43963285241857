import axios from "./index";

class TwitterApi {
  static List = () => {
    return axios.get(`${base}/`);
  };
  static RequestLink = (data) => {
    return axios.post(`${base}/`, data);
  };
}

let base = "twitter";

export default TwitterApi;
