import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useAuth } from "../../../auth-context/auth.context";
import AuthApi from "../../../api/auth";

function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [show, setShow] = React.useState(false);
  const [error, setError] = useState(undefined);

  const { user, setUser } = useAuth();

  const history = useHistory();

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const handleClick = () => setShow(!show);

  const login = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (user && user.token) {
      return history.push("/admin/instagram");
    }
    if (username === "") {
      return setError("You must enter your username.");
    }
    if (password === "") {
      return setError("You must enter your password");
    }

    await AuthApi.Login({
      username,
      password,
    }).then((response) => {
      if (response.data && response.data.success === false) {
        return setError(response.data.detail);
      }
      return setProfile(response, username);
    }).catch((err) => {
      console.log(err);
      if (err.response.data.detail) {
        return setError(err.response.data.detail);
      }
      return setError("There has been an error.");
    });
  };

  const setProfile = async (response, username) => {
    let user = {
      "id": "277c9095-547c-4735-b97e-289a83021d95",
      "username": username,
      "email": "aaaa@aaaa.com",
      "date": "2024-01-11T06:38:02.000Z",
      "user_role": null
    };
    user.token = response.data.access;
    user = JSON.stringify(user);
    setUser(user);
    localStorage.setItem("user", user);
    return history.push("/admin/instagram");
  };

  return (
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w='100%'
            mx={{ base: "auto", lg: "0px" }}
            me='auto'
            h='100%'
            alignItems='start'
            justifyContent='center'
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}
            flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Sign IN
            </Heading>
            <Text
                mb='36px'
                ms='4px'
                color={textColorSecondary}
                fontWeight='400'
                fontSize='md'>
              Bot download and manage data
            </Text>
          </Box>
          <Flex
              zIndex='2'
              direction='column'
              w={{ base: "100%", md: "420px" }}
              maxW='100%'
              background='transparent'
              borderRadius='15px'
              mx={{ base: "auto", lg: "unset" }}
              me='auto'
              mb={{ base: "20px", md: "auto" }}>

            <Flex
                zIndex='2'
                direction='column'
                w={{ base: "100%", md: "420px" }}
                maxW='100%'
                background='transparent'
                borderRadius='15px'
                mx={{ base: "auto", lg: "unset" }}
                me='auto'
                mb={{ base: "20px", md: "auto" }}>
              <h4
                  style={{
                    fontSize: ".9em",
                    color: "red",
                    textAlign: "center",
                    fontWeight: 400,
                    transition: ".2s all",
                  }}
              >
                {error}
              </h4>
              <FormControl>
                <FormLabel
                    display='flex'
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    mb='8px'>
                  Username<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                    isRequired={true}
                    variant='auth'
                    fontSize='sm'
                    ms={{ base: "0px", md: "0px" }}
                    type='text'
                    placeholder='username'
                    mb='24px'
                    defaultValue={username}
                    fontWeight='500'
                    size='lg'
                    onChange={(event) => {
                      setUsername(event.target.value);
                      setError(undefined);
                    }}
                />
                <FormLabel
                    ms='4px'
                    fontSize='sm'
                    fontWeight='500'
                    color={textColor}
                    display='flex'>
                  Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size='md'>
                  <Input
                      isRequired={true}
                      fontSize='sm'
                      placeholder='Min. 8 characters'
                      mb='24px'
                      size='lg'
                      defaultValue={password}
                      type={show ? "text" : "password"}
                      variant='auth'
                      onChange={(event) => {
                        setPassword(event.target.value);
                        setError(undefined);
                      }}
                  />
                  <InputRightElement display='flex' alignItems='center' mt='4px'>
                    <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
                <Flex justifyContent='space-between' align='center' mb='24px'>
                  <FormControl display='flex' alignItems='center'>

                  </FormControl>
                </Flex>
                <Button
                    fontSize='sm'
                    variant='brand'
                    fontWeight='500'
                    w='100%'
                    h='50'
                    mb='24px'
                    onClick={login}>
                  Sign In
                </Button>
              </FormControl>
              <Flex
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='start'
                  maxW='100%'
                  mt='0px'>
                <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                  Not registered yet?
                  <NavLink to='/auth/sign-up'>
                    <Text
                        color={textColorBrand}
                        as='span'
                        ms='5px'
                        fontWeight='500'>
                      Create an Account
                    </Text>
                  </NavLink>
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>

  );
}

export default SignIn;
