import {
    Box,
    SimpleGrid,
} from "@chakra-ui/react";
import TableView from "./table";
import {ColumnsTable} from "./columnsTable";

export default function Instagram() {
  // Chakra Color Mode
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid
            mb='20px'
            columns={{ sm: 1, md: 1 }}
            spacing={{ base: "20px", xl: "20px" }}>
              <TableView
                  columnsData={ColumnsTable}
              />
          </SimpleGrid>
        </Box>
    );
}
