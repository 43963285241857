import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdLogout, MdLogin, MdLock,
} from "react-icons/md";
import { RxInstagramLogo } from "react-icons/rx";
import Instagram from "views/admin/Instagram";
import SignIn from "views/auth/signIn/index.jsx";
import SignUp from "./views/auth/signUp";
import {FaYoutubeSquare} from "react-icons/fa";
import Youtube from "./views/admin/Youtube";
import Twitter from "./views/admin/Twitter";
import {FaSquareXTwitter} from "react-icons/fa6";

const routes = [
  {
    name: "Instagram",
    layout: "/admin",
    icon: <Icon as={RxInstagramLogo} width='20px' height='20px' color='inherit' />,
    path: "/instagram",
    component: Instagram,
  },
  {
    name: "Youtube",
    layout: "/admin",
    icon: <Icon as={FaYoutubeSquare} width='20px' height='20px' color='inherit' />,
    path: "/youtube",
    component: Youtube,
  },
  {
    name: "Twitter",
    layout: "/admin",
    icon: <Icon as={FaSquareXTwitter} width='20px' height='20px' color='inherit' />,
    path: "/twitter",
    component: Twitter,
  },
  {
    name: "Signin",
    layout: "/auth",
    path: "/sign-in",
    icon: (
        <Icon as={MdLogin} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
    hide: true
  },
  {
    name: "Sign up",
    layout: "/auth",
    path: "/sign-up",
    icon: (
        <Icon as={MdLock} width='16px' height='16px' color='inherit' />
    ),
    component: SignUp,
    hide: true
  },
];

export const Logout = [
  {
    name: "Log Out",
    layout: "/auth",
    path: "/sign-out",
    icon: (
      <Icon as={MdLogout} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
  }
];
export default routes;
