export const ColumnsTable = [
    {
        Header: "Name",
        accessor: "screen_name",
    },
    {
        Header: "Text",
        accessor: "full_text",
    },
    {
        Header: "LINK",
        accessor: "requestLink",
    },
    {
        Header: "COUNT",
        accessor: "request.length",
    },
    {
        Header: "REQUEST AT",
        accessor: "timeRequest",
    },
    {
        Header: "STATUS",
        accessor: "status",
    },
    {
        Header: "MESSAGE",
        accessor: "message",
    },
    {
        Header: "DOWNLOAD",
        accessor: "request",
    },
];
