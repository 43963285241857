/* eslint-disable */
import {
    Flex,
    Icon,
    IconButton,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper, Select,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import React, {useEffect, useMemo, useState} from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";
import InstagramApi from "../../../api/instagram";
import {HttpStatusCode} from "axios";
import {MdDownloading, MdOutlineAutoAwesomeMosaic} from "react-icons/md";
import { PiSlideshowLight } from "react-icons/pi";
import { BsClockHistory } from "react-icons/bs";
import {GoUnverified, GoVerified} from "react-icons/go";
import {VscError} from "react-icons/vsc";
import {FaExternalLinkAlt} from "react-icons/fa";
import moment from "moment";
import {ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import {CSVLink} from "react-csv";

export default function TableView(props) {
    const {columnsData} = props;
    const toast = useToast()

    const [data, setData] = useState([]);
    const [csvLink, setCsvLink] = useState();
    const columns = useMemo(() => columnsData, [columnsData]);

    const tableInstance = useTable(
        {
            columns,
            data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = tableInstance;
    initialState.pageSize = 10

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const iconColor = useColorModeValue("secondaryGray.500", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    function getList(){
        InstagramApi.List().then((resp) => {
            if (resp.status !== HttpStatusCode.Ok || resp.data.success === false) {
                toast({
                    title: 'Error',
                    description: resp.data.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: false,
                });
            } else
                setData(resp.data.data);
        }).catch(() => {
            toast({
                title: 'Error',
                description: 'Request Error',
                status: 'error',
                duration: 5000,
                isClosable: false,
            });
        });
    }

    useEffect(() => {
        getList();
    }, []);
    function openUrl(url){
        const newWin = window.open(url, '_blank');
        if(!newWin || newWin.closed || typeof newWin.closed=='undefined')
        {
            alert('enable popups permission for open all links');
        }
    }

    return (
        <Card
            direction='column'
            w='100%'
            px='0px'
            overflowX={{sm: "scroll", lg: "hidden"}}>
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Text
                    color={textColor}
                    fontSize='22px'
                    fontWeight='700'
                    lineHeight='100%'>
                    Instagram Table
                </Text>
                <CSVLink
                    data={data}
                    filename={'instagram download request ' + moment(moment.now()).format('YYYY/MM/DD - HH:mm') + '.csv'}
                    className="hidden"
                    ref={(r) => (setCsvLink(r))}
                    target="_blank"
                />
                <Menu
                    pageTitle='Instagram'
                    handleAddClick={(url) => {
                        InstagramApi.RequestLink({url}).then((resp) => {
                            if (resp.status !== HttpStatusCode.Ok || resp.data.success === false) {
                                toast({
                                    title: 'Error',
                                    description: resp.data.message,
                                    status: 'error',
                                    duration: 5000,
                                    isClosable: false,
                                });
                            } else {
                                toast({
                                    title: 'Success',
                                    description: resp.data.data.message,
                                    status: 'error',
                                    duration: 5000,
                                    isClosable: false,
                                });
                                resp.data.data.request.forEach((item) => {
                                    openUrl(item.dataLink);
                                });
                                getList();
                            }
                        }).catch(() => {
                            toast({
                                title: 'Error',
                                description: 'Request Error',
                                status: 'error',
                                duration: 5000,
                                isClosable: false,
                            });
                        });
                    }}
                    handleDownloadCsv={() => {
                        csvLink.link.click();
                    }}
                />
            </Flex>
            <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
                <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <Th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    pe='10px'
                                    key={index}
                                    borderColor={borderColor}>
                                    <Flex
                                        justify='space-between'
                                        align='center'
                                        fontSize={{sm: "10px", lg: "12px"}}
                                        color='gray.400'>
                                        {column.render("Header")}
                                    </Flex>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell, index) => {
                                    let data = "";
                                    if (cell.column.Header === "TYPE") {
                                        data = (
                                            <Flex align='center'>
                                                {
                                                    cell.value === "post" ?
                                                        <Icon
                                                            as={PiSlideshowLight}
                                                            color='darkorange'
                                                            w='24px'
                                                            h='24px'
                                                        />
                                                    : cell.value === "story" ?
                                                        <Icon
                                                            as={BsClockHistory}
                                                            color='red' w='24px' h='24px'
                                                        />
                                                    :
                                                        <Icon
                                                            as={MdOutlineAutoAwesomeMosaic}
                                                            color={iconColor} w='24px' h='24px'
                                                        />
                                                }
                                            </Flex>
                                        );
                                    }
                                    else if (cell.column.Header === "STATUS") {
                                        data = (
                                            <Flex align='center'>
                                                {
                                                    cell.value === "success" ?
                                                        <Icon
                                                            as={GoVerified}
                                                            color='darkgreen'
                                                            w='24px'
                                                            h='24px'
                                                        />
                                                        : cell.value === "error" ?
                                                            <Icon
                                                                as={VscError}
                                                                color='red' w='24px' h='24px'
                                                            />
                                                            :
                                                            <Icon
                                                                as={GoUnverified}
                                                                color='orangered' w='24px' h='24px'
                                                            />

                                                }
                                            </Flex>
                                        );
                                    }
                                    else if (cell.column.Header === "DOWNLOAD") {
                                        data = (
                                            <IconButton
                                                as={MdDownloading}
                                                color='deepskyblue' w='30px' h='30px'
                                                onClick={() => {
                                                    cell.value.forEach((item) => {
                                                        openUrl(item.dataLink);
                                                    });
                                                }}
                                            />
                                        );
                                    }
                                    else if (cell.column.Header === "LINK") {
                                        data = (
                                            <a href={cell.value} target='_blank'>
                                                <Icon
                                                    as={FaExternalLinkAlt}
                                                    color='deepskyblue' w='18px' h='18px'
                                                />
                                            </a>
                                        );
                                    }
                                    else if (cell.column.Header === "REQUEST AT") {
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {moment(cell.value).format('YYYY/MM/DD - HH:mm')}
                                            </Text>
                                        );
                                    }
                                    else
                                        data = (
                                            <Text color={textColor} fontSize='sm' fontWeight='700'>
                                                {cell.value}
                                            </Text>
                                        );
                                    return (
                                        <Td
                                            {...cell.getCellProps()}
                                            key={index}
                                            fontSize={{sm: "14px"}}
                                            minW={{sm: "150px", md: "200px", lg: "auto"}}
                                            borderColor='transparent'>
                                            {data}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            <Flex justifyContent="space-between" m={4} alignItems="center">
                <Flex>
                    <Tooltip label="First Page">
                        <IconButton
                            onClick={() => gotoPage(0)}
                            isDisabled={!canPreviousPage}
                            icon={<ArrowLeftIcon h={3} w={3} />}
                            mr={4}
                        />
                    </Tooltip>
                    <Tooltip label="Previous Page">
                        <IconButton
                            onClick={previousPage}
                            isDisabled={!canPreviousPage}
                            icon={<ChevronLeftIcon h={6} w={6} />}
                        />
                    </Tooltip>
                </Flex>

                <Flex alignItems="center">
                    <Text flexShrink="0" mr={8}>
                        Page{" "}
                        <Text fontWeight="bold" as="span">
                            {pageIndex + 1}
                        </Text>{" "}
                        of{" "}
                        <Text fontWeight="bold" as="span">
                            {pageOptions.length}
                        </Text>
                    </Text>
                    <Text flexShrink="0">Go to page:</Text>{" "}
                    <NumberInput
                        ml={2}
                        mr={8}
                        w={28}
                        min={1}
                        max={pageOptions.length}
                        onChange={(value) => {
                            const page = value ? value - 1 : 0;
                            gotoPage(page);
                        }}
                        defaultValue={pageIndex + 1}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    <Select
                        w={32}
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </Select>
                </Flex>

                <Flex>
                    <Tooltip label="Next Page">
                        <IconButton
                            onClick={nextPage}
                            isDisabled={!canNextPage}
                            icon={<ChevronRightIcon h={6} w={6} />}
                        />
                    </Tooltip>
                    <Tooltip label="Last Page">
                        <IconButton
                            onClick={() => gotoPage(pageCount - 1)}
                            isDisabled={!canNextPage}
                            icon={<ArrowRightIcon h={3} w={3} />}
                            ml={4}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
        </Card>
    );
}
