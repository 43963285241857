export const ColumnsTable = [
    {
        Header: "AUTHOR",
        accessor: "author",
    },
    {
        Header: "TITLE",
        accessor: "title",
    },
    {
        Header: "LINK",
        accessor: "requestLink",
    },
    {
        Header: "CHANNEL",
        accessor: "channelLink",
    },
    {
        Header: "QUALITY COUNT",
        accessor: "request.length",
    },
    {
        Header: "REQUEST AT",
        accessor: "timeRequest",
    },
    {
        Header: "EXPIRATION",
        accessor: "expiration",
    },
    {
        Header: "STATUS",
        accessor: "status",
    },
    {
        Header: "MESSAGE",
        accessor: "message",
    },
    {
        Header: "DOWNLOAD",
        accessor: "request",
    },
];
