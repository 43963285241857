export const ColumnsTable = [
    {
        Header: "USERNAME",
        accessor: "instagramUser.username",
    },
    {
        Header: "LINK",
        accessor: "requestLink",
    },
    {
        Header: "COUNT",
        accessor: "request.length",
    },
    {
        Header: "TYPE",
        accessor: "type",
    },
    {
        Header: "STATUS",
        accessor: "status",
    },
    {
        Header: "REQUEST AT",
        accessor: "timeRequest",
    },
    {
        Header: "MESSAGE",
        accessor: "message",
    },
    {
        Header: "DOWNLOAD",
        accessor: "request",
    },
];
